define("discourse/plugins/discourse-ai/discourse/components/ai-topic-gist", ["exports", "@glimmer/component", "@ember/service", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiTopicGist extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    get showGist() {
      return this.router.currentRoute.attributes?.filterType === "hot" && this.args.topic?.ai_topic_gist && !this.args.topic?.excerpt;
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.showGist}}
          <div class="ai-topic-gist">
            <div class="ai-topic-gist__text">
              {{@topic.ai_topic_gist}}
            </div>
          </div>
        {{/if}}
      
    */
    {
      "id": "JbAgrzAo",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"showGist\"]],[[[1,\"      \"],[10,0],[14,0,\"ai-topic-gist\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"ai-topic-gist__text\"],[12],[1,\"\\n          \"],[1,[30,1,[\"ai_topic_gist\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@topic\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/ai-topic-gist.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AiTopicGist;
});