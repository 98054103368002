define("discourse/plugins/discourse-ai/discourse/components/ai-gist-disclosure", ["exports", "@glimmer/component", "@ember/service", "discourse-common/helpers/d-icon", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _dIcon, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiGistDisclosure extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    get shouldShow() {
      return this.router.currentRoute.attributes?.list?.topics?.some(topic1 => topic1.ai_topic_gist);
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.shouldShow}}
          <span class="ai-topic-gist__disclosure">
            {{icon "discourse-sparkles"}}
            {{i18n "discourse_ai.summarization.disclosure"}}
          </span>
        {{/if}}
      
    */
    {
      "id": "k1R/pCEO",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldShow\"]],[[[1,\"      \"],[10,1],[14,0,\"ai-topic-gist__disclosure\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"discourse-sparkles\"],null]],[1,\"\\n        \"],[1,[28,[32,1],[\"discourse_ai.summarization.disclosure\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/ai-gist-disclosure.js",
      "scope": () => [_dIcon.default, _i18n.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AiGistDisclosure;
});